import React, { FC, Fragment, useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import { color, Theme } from '@/utils/themes'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import ThemeContext from '@/context/Theme'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Heading from '@/components/snippets/Heading'
import Typography from '@/components/typography'
import Card3 from '@/components/snippets/Card3'
import Card4 from '@/components/snippets/Card4'
import ServiceLogo from '@/components/snippets/ServiceLogo'

interface Props {
  [key: string]: any
}

interface StyleProps extends Props {
  theme: Theme
}

const Pricing: FC<Props> = ({ ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root color="primary.10" theme={theme} {...others}>
      <Container
        responsive={{
          default: 1,
          sm: 2
        }}
      >
        <Heading>
          <Heading_Text>メニュー例</Heading_Text>
        </Heading>
        <Wrapper theme={theme}>
          <Menu>
            <Container nested={true} responsive={{ default: 1, md: 1 }}>
              <Menu_Wrapper>
                <Menu_List>
                  <Menu_Item>
                    <Card3
                      image={
                        <StaticImage
                          src="./images/menu-2.png"
                          alt=""
                          placeholder="none"
                          quality={100}
                          loading="eager"
                        />
                      }
                      title={<Fragment>募集業務</Fragment>}
                      tags={[
                        'レインズ掲載',
                        'ポータルサイト掲載',
                        '内見手配',
                        '申し込み対応'
                      ]}
                    />
                  </Menu_Item>
                  <Menu_Item>
                    <Card3
                      image={
                        <StaticImage
                          src="./images/menu-3.png"
                          alt=""
                          placeholder="none"
                          quality={100}
                          loading="eager"
                        />
                      }
                      title={<Fragment>入力業務</Fragment>}
                      tags={[
                        '業者間流通サイト掲載',
                        'ポータルサイト',
                        '賃貸管理システム入力',
                        'エクセル',
                        'ワード'
                      ]}
                    />
                  </Menu_Item>
                  <Menu_Item>
                    <Card3
                      image={
                        <StaticImage
                          src="./images/menu-4.png"
                          alt=""
                          placeholder="none"
                          quality={100}
                          loading="eager"
                        />
                      }
                      title={<Fragment>解約業務</Fragment>}
                      tags={['解約受付', '原状回復見積・発注']}
                    />
                  </Menu_Item>
                  <Menu_Item>
                    <Card3
                      image={
                        <StaticImage
                          src="./images/menu-5.png"
                          alt=""
                          placeholder="none"
                          quality={100}
                          loading="eager"
                        />
                      }
                      title={<Fragment>工事発注</Fragment>}
                      tags={[
                        '工事業者選定',
                        '見積発注',
                        '工事発注',
                        'オーナー請求'
                      ]}
                    />
                  </Menu_Item>
                  <Menu_Item>
                    <Card3
                      image={
                        <StaticImage
                          src="./images/menu-6.png"
                          alt=""
                          placeholder="none"
                          quality={100}
                          loading="eager"
                        />
                      }
                      title={<Fragment>入金付け合わせ</Fragment>}
                      tags={['家賃付け合わせ', '入金管理']}
                    />
                  </Menu_Item>
                  <Menu_Item>
                    <Card3
                      image={
                        <StaticImage
                          src="./images/menu-7-2.png"
                          alt=""
                          placeholder="none"
                          quality={100}
                          loading="eager"
                        />
                      }
                      title={<Fragment>更新</Fragment>}
                      tags={['入居者更新通知', '更新契約書作成']}
                    />
                  </Menu_Item>
                  <Menu_Item>
                    <Bubble>
                      <StaticImage
                        src="./images/menu-8.png"
                        alt="賃貸管理ならどんな事でもご依頼いただけます。記載にない事でもご相談ください。"
                        placeholder="none"
                        quality={100}
                        loading="eager"
                      />
                    </Bubble>
                  </Menu_Item>
                </Menu_List>
              </Menu_Wrapper>
            </Container>
          </Menu>
        </Wrapper>
        <Wrapper theme={theme} color="primary">
          <Points>
            <Subheading
              theme={theme}
              color="black.0"
              variant="h3"
              responsive={{
                md: 'h4',
                sm: 'h4'
              }}
            >
              <Subheading_Text>
                <ServiceLogo css={styles.ServiceLogo({ theme })} />
                がこだわる
              </Subheading_Text>
              <br className="v-sm" />
              <strong>3つ</strong>のポイント
            </Subheading>
            <Container nested={true} responsive={{ default: 1, md: 2 }}>
              <Points_Wrapper>
                <Points_List>
                  <Points_Item>
                    <Card4
                      number={{
                        value: 1,
                        caption: 'POINT'
                      }}
                      heading="最も簡単に依頼可能"
                      text={
                        <Fragment>
                          複雑な管理業務を簡単に依頼できるよう「専用フォーム」「事前ヒアリング」を設けることで、何度もやりとりする手間を省きます。
                        </Fragment>
                      }
                    />
                  </Points_Item>
                  <Points_Item>
                    <Card4
                      number={{
                        value: 2,
                        caption: 'POINT'
                      }}
                      heading="賃貸管理アシスタントの品質"
                      text={
                        <Fragment>
                          より正確に、よりスピーディーに業務を完了させるために、経験豊富な賃貸管理アシスタント採用を徹底しています。
                        </Fragment>
                      }
                    />
                  </Points_Item>
                  <Points_Item>
                    <Card4
                      number={{
                        value: 3,
                        caption: 'POINT'
                      }}
                      heading="徹底フォロー"
                      text={
                        <Fragment>
                          お忙しい管理業務担当者が、依頼する事自体を手間だと感じないようコンシェルジュが徹底フォローします。
                        </Fragment>
                      }
                    />
                  </Points_Item>
                </Points_List>
              </Points_Wrapper>
            </Container>
          </Points>
        </Wrapper>
      </Container>
    </Root>
  )
}

export default Pricing

const Root = styled(Section)<StyleProps>``

const Heading_Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div<StyleProps>`
  margin-top: ${rem(48)};
  padding: ${rem(80)} ${rem(40)};
  background-color: ${(props) => {
    return props.color ? color()(props) : color('black.0')(props)
  }};
  @media ${mq.and(breakpoints.md)} {
    margin-top: ${rem(32)};
    padding: ${rem(64)} ${rem(40)};
  }
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(24)};
    padding: ${rem(32)} ${rem(20)} ${rem(48)};
  }
`

const Menu = styled.div``

const Subheading = styled(Typography)<StyleProps>`
  text-align: center;
  color: ${color()};
  strong {
    font-size: 1.5em;
  }
`

const Subheading_Text = styled.span``

const Menu_Wrapper = styled.div`
  margin-top: ${rem(40)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(24)};
  }
`

const Menu_List = styled.ul`
  @media ${mq.and(breakpoints.gt_sm)} {
    display: flex;
    flex-wrap: wrap;
    margin: ${rem(-40)} ${rem(-20)} 0;
  }
  @media ${mq.and(breakpoints.md)} {
    margin: ${rem(-40)} ${rem(-12)} 0;
  }
`

const Menu_Item = styled.li`
  @media ${mq.and(breakpoints.gt_lg)} {
    margin-top: ${rem(40)};
    width: 25%;
    padding: 0 ${rem(20)};
  }
  @media ${mq.and(breakpoints.lg)} {
    margin-top: ${rem(40)};
    width: 33.333333%;
    padding: 0 ${rem(20)};
  }
  @media ${mq.and(breakpoints.md)} {
    margin-top: ${rem(40)};
    width: 50%;
    padding: 0 ${rem(12)};
  }
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(40)};
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Bubble = styled.div`
  @media ${mq.and(breakpoints.sm)} {
    margin: 0 auto;
    max-width: ${rem(240)};
  }
`

const Points = styled.div``

const Points_Wrapper = styled.div`
  margin-top: ${rem(40)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(24)};
  }
`

const Points_List = styled.ul`
  @media ${mq.and(breakpoints.gt_md)} {
    display: flex;
    flex-wrap: wrap;
    margin: ${rem(-40)} ${rem(-20)} 0;
  }
  @media ${mq.and(breakpoints.lg)} {
    margin: ${rem(-40)} ${rem(-12)} 0;
  }
`

const Points_Item = styled.li`
  @media ${mq.and(breakpoints.gt_md)} {
    margin-top: ${rem(40)};
    width: 33.333333%;
    padding: 0 ${rem(20)};

    > * {
      height: 100%;
    }
  }
  @media ${mq.and(breakpoints.lg)} {
    padding: 0 ${rem(12)};
  }
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    margin-top: ${rem(40)};
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const styles = {
  ServiceLogo: (props: StyleProps) => css`
    color: ${color('black.0')(props)};
    display: inline-block;
    vertical-align: sub;
    margin-right: ${rem(8)};
    width: ${rem(119)};
    height: ${rem(40)};
  `
}
